/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
body, ion-content, ion-item, ion-title, ion-grid, ion-row, ion-col, ion-input, ion-segment, input {
  font-family: "Poppins", sans-serif;
}

.alert-button-role-cancel {
  color: #764AF1 !important;
}

.alert-message {
  font-size: 12px !important;
  color: red !important;
}

.alert-sub-title {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.alert-button-role-confirm, .alert-button-role-Resend {
  color: #764AF1 !important;
}

ion-item.ion-invalid.ion-touched {
  border-bottom: 2px solid var(--ion-color-danger) !important;
}

ion-item.ion-valid.ion-touched {
  border-bottom: 2px solid var(--ion-color-success) !important;
}

.toast {
  margin-top: 30px;
}

.ionic-selectable-cover {
  position: unset !important;
}

.ionic-selectable-value {
  padding: 6px 0 !important;
}

.alert-button.sc-ion-alert-md {
  color: #764AF1;
}